import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import tournamemt from './assets/tournament.jpeg';
import ins from './assets/ins.jpg';
import inov from './assets/ino.jpg';
import lab from './assets/lab.jpg';
import './Service.css';



const Services = () => {
    return (
      <>
        <Container className="services-section">
          <Row className="service-row align-items-center">
            <Col md={6}>
              <Card.Img src={tournamemt} className="img-fluid" />
            </Col>
            <Col md={6}>
              <h3>Robotics Tournaments</h3>
              <p>
                Since 2017, we have engaged schools in different robotics tournaments that bring together
                students and industry players as mentors. Students are challenged to create solutions based on the
                theme given to them by designing a robot, programming, and sharing their project with professional judges.
              </p>
              <Link to="/robotics-tournament">
                <Button variant="primary">Learn More</Button>
              </Link>
            </Col>
          </Row>
  
          <Row className="service-row align-items-center">
            <Col md={6} className="order-md-2">
              <Card.Img src={ins} className="img-fluid" />
            </Col>
            <Col md={6} className="order-md-1">
              <h3>After-School Robotics</h3>
              <p>
                In partnership with schools, we offer robotics training to students
                through interactive software with stories they can relate to.
              </p>
              <Link to="/after-school-robotics">
                <Button variant="primary">Learn More</Button>
              </Link>
            </Col>
          </Row>
  
          <Row className="service-row align-items-center">
            <Col md={6}>
              <Card.Img src={lab} className="img-fluid" />
            </Col>
            <Col md={6}>
              <h3>Mobile Robotics Labs</h3>
              <p>
                Our program brings robotics closer to you, whether in your community,
                home, or school. We ensure students gain hands-on robotics experience.
              </p>
              <Link to="/mobile-robotic-lab">
                <Button variant="primary">Learn More</Button>
              </Link>
            </Col>
          </Row>
  
          <Row className="service-row align-items-center">
            <Col md={6} className="order-md-2">
              <Card.Img src={inov} className="img-fluid" />
            </Col>
            <Col md={6} className="order-md-1">
              <h3>Innovation Center</h3>
              <p>
                Located in Lavington, our central training center allows students to
                design models, work on real-life prototypes, and engage in tailored
                robotics training.
              </p>
              <Link to="/innovation-centre">
                <Button variant="primary">Learn More</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

export default Services;
