import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';




import { FaWhatsapp } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="home">
      {/* SEO Optimization */}
      <Helmet>
        <title>Stemtrix - Empowering STEM Education in Kenya</title>
        <meta name="description" content="Empowering the next generation with hands-on robotics and innovative learning." />
        <meta property="og:title" content="Stemtrix - Home" />
        <meta property="og:description" content="Join our robotics training programs and robotics tournament." />
        <meta property="og:url" content="https://stemtrix.co.ke/" />
        <meta name="keywords" content="Robotics, STEM, Kids Education, Coding, Lego Robotics, STEMtrix, Robotics Training, Tournaments, Holiday camp, After school program, Extra Curricular activity, Inspire Robotics Challenge, Robofest, Lego education" />
      </Helmet>

      {/* Hero Section */}
      <div className="hero">
        <div className="overlay"></div>
        <div className="hero-content">
          <h1>Unlocking Creativity with Hands-On Robotics</h1>
          <p>Learn, build, and innovate with hands-on STEM experiences.</p>
          <div className="hero-buttons">

            <Link to="/sign" className="btn-secondary">Join Our STEM Program</Link>
          </div>
        </div>
      </div>

         {/* New Robotics Kits Section */}
         <section className="robotics-kits">
        <div className="kits-content">
          <h2>Explore Our Educational Robotics Kits</h2>
          <p>Empower young minds with top-quality robotics kits designed for schools and home learning. 
          Our kits inspire creativity, problem-solving, and hands-on STEM innovation.</p>
          <Link to="/products" className="btn-primary">Discover Robotics Kits</Link>
        </div>
      </section>

      {/* Features Section */}
      <section className="features">
        <h2>Why Choose Stemtrix?</h2>
        <div className="feature-cards">
          <div className="feature-card">
            <h3>Project-Based Learning</h3>
            <p>Our hands-on approach helps students solve real-world problems.</p>
          </div>
          <div className="feature-card">
            <h3>Expert Mentors</h3>
            <p>Learn from professionals in robotics and technology.</p>
          </div>
          <div className="feature-card">
            <h3>Exciting Competitions</h3>
            <p>Participate in global robotics challenges and tournaments.</p>
          </div>
        </div>
      </section>
      

      {/* WhatsApp Floating Button */}
      <a href="https://wa.me/254110394940" className="whatsapp-float" target="_blank" rel="noopener noreferrer">
        <FaWhatsapp className="whatsapp-icon" />
      </a>

      {/* News Section */}
      <Container className="news-section my-5">
        <h2 className="text-center mb-4">Tournaments Reviews</h2>
        <Row className="mb-5">
          <Col md={6}>
            <Card className="news-card">
              <iframe
                src="https://www.youtube.com/embed/cfNnN_UxdpQ"
                title="Riara Springs Girls High School"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="video-frame w-100"
              ></iframe>
              <Card.Body>
                <Card.Title>Riara Springs Girls High School</Card.Title>
                <p>
                  Riara Springs Girls High School are the winners of the Stemtrix Robotics Competition Nairobi Edition. The competition attracted 19 schools, with 'Nanobytes' emerging victorious.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="news-card">
              <iframe
                src="https://www.youtube.com/embed/RKgyaGQUqsE"
                title="2023-2024 Robotics Season"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="video-frame w-100"
              ></iframe>
              <Card.Body>
                <Card.Title>2023-2024 Robotics Season Kicks Off</Card.Title>
                <p>
                  The 2023-2024 Robotics Season has kicked off. Students have the opportunity to test their creativity in both the regional competition and the 'Masterpiece' First Lego League Challenge.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card className="news-card">
              <iframe
                src="https://www.youtube.com/embed/wuryEJJwduc"
                title="Green Garden Girls High School"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="video-frame w-100"
              ></iframe>
              <Card.Body>
                <Card.Title>Green Garden Girls High School Representing Kenya</Card.Title>
                <p>
                  Ten girls from Green Garden Girls High School in Kiambu will represent Kenya and East Africa in the World's First Robotics Education Competition in Houston, Texas.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomePage;
