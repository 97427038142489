import React, { useState } from "react";
import './Sign.css';

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    age: "",
    location: "",
    preference: "",
    phone: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // WhatsApp message
    const whatsappMessage = `Hello, my name is ${formData.name}. I would like to enroll my child (Age: ${formData.age}) in your robotics program. Preferred location: ${formData.preference}. We are located in ${formData.location}. Contact me at ${formData.phone}, Email: ${formData.email}.`;

    const whatsappLink = `https://wa.me/254110394940?text=${encodeURIComponent(whatsappMessage)}`;
    
    // Open WhatsApp
    window.open(whatsappLink, "_blank");

    // Send Email
    window.location.href = `mailto:stemtrix@gmail.com?subject=Class Registration&body=${encodeURIComponent(whatsappMessage)}`;
  };

  return (
    <div className="signup-form">
      <h2>Secure Your Spot Today</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="number"
          name="age"
          placeholder="Child's Age"
          value={formData.age}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="location"
          placeholder="Your Location"
          value={formData.location}
          onChange={handleChange}
          required
        />
        <select name="preference" value={formData.preference} onChange={handleChange} required>
          <option value="">Select Preference</option>
          <option value="Lavington Centre">Lavington Centre</option>
          <option value="Home School">Home School</option>
        </select>
        <input
          type="tel"
          name="phone"
          placeholder="Your Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Signup
