import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import logo from './logo3.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/" style={{ paddingLeft: '10px' }}>
          <img src={logo} alt="Logo" height="100" />
        </a>
        
        {/* Toggle Button - Changes to "X" when menu is open */}
        <button className="navbar-toggler" type="button" onClick={toggleMenu} aria-label="Toggle navigation">
          {menuOpen ? <span className="close-icon">&times;</span> : <span className="navbar-toggler-icon"></span>}
        </button>

        <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink exact to="/" className="nav-link" activeClassName="active" onClick={closeMenu}>Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/services" className="nav-link" activeClassName="active" onClick={closeMenu}>Services</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/about" className="nav-link" activeClassName="active" onClick={closeMenu}>About</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/programs" className="nav-link" activeClassName="active" onClick={closeMenu}>Programs</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/products" className="nav-link" activeClassName="active" onClick={closeMenu}>Products</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/contact" className="nav-link" activeClassName="active" onClick={closeMenu}>Contacts</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
